
import React, { useState,useEffect } from 'react';
import { Button } from 'primereact/button';
import { InputText } from 'primereact/inputtext';

import Ban  from "../assets/images/Screen.png"
import Logo from '../assets/images/lognew.png'

import {  useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { useDispatch } from 'react-redux';
import { loginUser } from '../redux/apiCalls';
const Login = () => {
    const { userInfo,isLoading } = useSelector((state) => state.user);
    // const [load, setLoad] = useState(false);
    const [em, setEm] = useState("");
    const [pwd, setPwd] = useState("");
    const nav = useNavigate()
const dispatch = useDispatch();
    const login= ()=>{
        if(!em || !pwd){
alert('input login details')
        }
        else{
loginUser({email:em,password:pwd},dispatch)
        }
    }
    useEffect(() => {
      if(userInfo){
nav('/')
      }

    }, [userInfo,nav])
    
  return (
   
    <div className="min-h-screen flex gap-5">
        <div className="hidden md:block w-6 bg-no-repeat bg-cover" style={{ backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),url(${Ban})`,
            
            // "url('../assets/images/emb.jpeg')" 
        }}
            
            >
                
            </div>
        <div className=" w-full md:w-6 p-6 md:p-8 align-self-center">
            <div className="mb-5 text-center">
                <img src={Logo} alt="hyper" height={70} width={180} className="mb-3" style={{objectFit:'contain'}} />
                <div className="text-900 text-3xl font-medium mb-3">Welcome Back</div>
                <span className="text-600 font-medium mr-2">Powered by Barnksforte Technologies MDM</span>
                {/* eslint-disable-next-line  */}
                {/* <a className="font-medium no-underline text-blue-500 cursor-pointer">Create today!</a> */}
            </div>
            <div>
                <label htmlFor="email2" className="block text-900 font-medium mb-2">Email</label>
                <InputText id="email2" type="text" onChange={(e)=>setEm(e.target.value)} className="w-full mb-3" />
    
                <label htmlFor="password2" className="block text-900 font-medium mb-2">Password</label>
                <InputText id="password2" type="password" onChange={(e)=>setPwd(e.target.value)} className="w-full mb-3" />
    
                {/* <div className="flex align-items-center justify-content-between mb-6">
                    <div className="flex align-items-center">
                        <Checkbox id="rememberme2" className="mr-2" checked={checked2} onChange={(e) => setChecked2(e.checked)} />
                        <label htmlFor="rememberme2">Remember me</label>
                    </div>
                   
                 eslint-disable-next-line 
                    <a className="font-medium no-underline ml-2 text-blue-500 text-right cursor-pointer">Forgot your password?</a>
                </div> */}
    
                <Button label="Sign In" icon="pi pi-user" loading={isLoading} onClick={login} className="w-full" />
            </div>
        </div>
       
        
    </div>
       
  )
}

export default Login
import { logout } from "./userSlice";

export const addUserToLocalStorage = ({ user, token }) => {
  localStorage.setItem("user", JSON.stringify(user));
  localStorage.setItem("token", token);
};

export const logoutUser = (dispatch) => {
  dispatch(logout());
  localStorage.removeItem("token");
  localStorage.removeItem("user");
//   localStorage.removeItem("shippingAddy");
//   localStorage.removeItem("payMode");
  window.location.href = "/login";
};
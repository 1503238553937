
import {
    
    loginStart,
    loginSuccess,
    loginFailure,
    
  } from "./userSlice";
  // import axios from 'axios';
import { addUserToLocalStorage } from "./functions";
import authFetch from "../axios";
export const loginUser = async (currentUser, dispatch) => {
    dispatch(loginStart());
    try {
      // https://lavish.onrender.com/api/v1/auth/register
      const res = await authFetch.post("login", currentUser);
      if(res.data.npc.status === 1){
        const { token } = res.data.npc;
        dispatch(loginSuccess(res.data.npc));
        addUserToLocalStorage({ user:res.data.npc, token });
      }
      else{
        dispatch(loginFailure());
        window.alert(res.data.npc.message);
      }
     
    } catch (error) {
      console.log(error.response);
      dispatch(loginFailure());
     
    }
    // clearAlert(dispatch);
  };

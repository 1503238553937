import { createSlice } from "@reduxjs/toolkit";
export const userSlice = createSlice({
  name: "user",
  initialState: {
    userInfo: localStorage.getItem("user")
      ? JSON.parse(localStorage.getItem("user"))
      : null,
    token: localStorage.getItem("token"),

    isLoading: false,
    error: false,
  },

  reducers: {
    registerStart: (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    registerSuccess: (state, action) => {
      return {
        ...state,
        isLoading: false,
        token: action.payload.token,
        userInfo: action.payload.user,
      };
    },
    registerFailure: (state) => {
      return {
        ...state,
        isLoading: false,
      };
    },
    loginStart: (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    loginSuccess: (state, action) => {
      return {
        ...state,
        isLoading: false,
        token: action.payload.token,
        userInfo: action.payload,
      };
    },
    loginFailure: (state, action) => {
      return {
        ...state,
        isLoading: false,
      };
    },

    logout: (state) => {
      return {
        ...state,
        userInfo: null,
        token: null,
error:false,
        isLoading: false,
      };
    },
    updateUserStart: (state) => {
      return {
        ...state,
        isLoading: true,
      };
    },
    updateUserSuccess: (state, action) => {
      return {
        ...state,
        isLoading: false,
        token: action.payload.token,
        userInfo: action.payload.user,
      };
    },
    updateUserFailure: (state, action) => {
      return {
        ...state,
        isLoading: false,
        error: true,
      };
    },
  },
});

export const {
  registerStart,
  registerSuccess,
  registerFailure,
  loginStart,
  loginSuccess,
  loginFailure,
  logout,
  updateUserFailure,
  updateUserStart,
  updateUserSuccess,
} = userSlice.actions;

export default userSlice.reducer;